<div class="footer background-dark-3">
  <a target="_blank" class="padding-left-m padding-right-m" rel="noopener" href="https://www.auckland.ac.nz/en/copyright.html">
    Copyright
  </a>
  <a
    class="border-left-light-2 padding-left-m padding-right-m"
    target="_blank"
    rel="noopener"
    href="https://www.auckland.ac.nz/en/privacy.html"
  >
    Privacy
  </a>
  <a
    class=" border-left-light-2 padding-left-m padding-right-m"
    target="_blank"
    rel="noopener"
    href="https://www.auckland.ac.nz/en/disclaimer.html"
  >
    Disclaimer
  </a>
  <a class=" border-left-light-2 padding-left-m padding-right-m" target="_blank" rel="noopener" href="https://uoa.custhelp.com/">
    Ask Auckland
  </a>
</div>
