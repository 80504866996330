// Service for opening a confirmation dialog

import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService {
    dialogRef: MatDialogRef<ConfirmationDialogComponent>;

    public flow: string;
    public keyword: string;

    constructor(private dialog: MatDialog) { }

    public open() {
        console.log('dialog');
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to publish updates to ${this.flow} - ${this.keyword}?`

        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = null;
        });
    }
}
