<h1>Audit Log</h1>

<div class="sort-table">
    <!-- List of stuff the user can sort by -->
    <div class="sort">
        <div class="sort__item">
            <label for="sort-by">Select Filter&nbsp;</label>
            <select id="sort-by" [(ngModel)]="sortByType">
                <option *ngFor="let sortType of sortByTypeList()" [value]="sortType">
                    {{sortType}}
                </option>
            </select>
        </div>
    </div>

    <!-- Date filter -->
    <div class="filter" *ngIf="sortByType === SortType.Date">
        <div class="filter__item">
            <label for="date-from">From</label>
            <input type="date" id="date-from" [(ngModel)]="dateFrom">
        </div>
        <div class="filter__item">
            <label class="date-to" for="date-to">To</label>
            <input type="date" id="date-to" [(ngModel)]="dateTo">
        </div>
    </div>

    <div class="filter"  *ngIf="sortByType === SortType.Flow">
        <label for="flow">Filter by contact flow:</label>
        <select name="flow" id="flow" [(ngModel)]="contactFlowFilter">
            <option *ngFor="let flow of contactFlowList" [value]="flow">
                {{flow}}
            </option>
        </select>
    </div>

    <div class="filter"  *ngIf="sortByType === SortType.Keyword">
        <label for="flow">Filter by contact keyword:</label>

        <select name="flow" id="keyword" [(ngModel)]="contactKeywordFilter">
            <option *ngFor="let keyword of contactKeywordList" [value]="keyword">
                {{keyword}}
            </option>
        </select>
    </div>

    <div class="filter"  *ngIf="sortByType === SortType.User">
        <label for="flow">Filter by user:</label>

        <select name="flow" id="user" [(ngModel)]="userFilter">
            <option *ngFor="let user of userList" [value]="user">
                {{user}}
            </option>
        </select>
    </div>
</div>

<div *ngIf="audits">
    <ng-container *ngFor="let audit of computedAudits()">
        <div class="audit">
            <div class="info">
                <div class="audit__action"><b>Flow</b>: {{ audit.p_key }}, <b>Keyword</b>: {{ audit.s_key }}</div>
                <div class="audit__action"><b>Event ID</b>: {{ audit.event_id }}</div>
                <div class="audit__action"><b>Changed by</b>: {{ audit.user_id }}</div>
                <div class="audit__date"><b>Timestamp</b>: {{ audit.event_timestamp | date: 'hh:mma dd-MM-yyyy' }}</div>
            </div>

            <div class="audit__sidebyside">
                <table class="responsive-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Original</th>
                            <th>New</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><b>Default Message</b></td>
                        <td>{{ audit.original_content.default_prompt }}</td>
                        <td>{{ audit.updated_content.default_prompt }}</td>
                    </tr>

                    <tr>
                        <td><b>Alternate Message</b></td>
                        <td>{{ audit.original_content.custom_prompt }}</td>
                        <td>{{ audit.updated_content.custom_prompt }}</td>
                    </tr>

                    <tr>
                        <td><b>Start</b></td>
                        <td *ngIf="audit.original_content.start_time && audit.original_content.start_time !== ''">{{ audit.original_content.start_time | date: 'hh:mma dd-MM-yyyy'}}</td>
                        <td *ngIf="!audit.original_content.start_time || audit.original_content.start_time === ''">No date</td>
                        <td *ngIf="audit.updated_content.start_time && audit.updated_content.start_time !== ''">{{ audit.updated_content.start_time | date: 'hh:mma dd-MM-yyyy'}}</td>
                        <td *ngIf="!audit.updated_content.start_time || audit.updated_content.start_time === ''">No date</td>
                    </tr>

                    <tr>
                        <td><b>End</b></td>
                        <td *ngIf="audit.original_content.end_time && audit.original_content.end_time !== ''">{{ audit.original_content.end_time | date: 'hh:mma dd-MM-yyyy'}}</td>
                        <td *ngIf="!audit.original_content.end_time || audit.original_content.end_time === ''">No date</td>
                        <td *ngIf="audit.updated_content.end_time && audit.updated_content.end_time !== ''">{{ audit.updated_content.end_time | date: 'hh:mma dd-MM-yyyy'}}</td>
                        <td *ngIf="!audit.updated_content.end_time || audit.updated_content.end_time === ''">No date</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <hr />
    </ng-container>
</div>
