<a href="/prompts">Back</a>

<div *ngIf="!prompt">
    <p>This prompt does not exist.</p>
</div>

<div *ngIf="prompt">

    <div class="header">
        <h1>Prompt Editor</h1>
    </div>

    <table class="responsive-table">
        <thead>
        <tr>
            <th rowspan="2">Flow</th>
            <th rowspan="2">Keyword</th>
            <th rowspan="2">Default Message Text</th>
            <th colspan="4">Alternate Message</th>
        </tr>
        <tr>
            <th>Text</th>
            <th>Start</th>
            <th>End</th>
            <th>Active</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ prompt.flow_name }}</td>
                <td>{{ prompt.keyword }}</td>

                <td>
                    <textarea rows="4" cols="50" [(ngModel)]="promptEdit.default_prompt">
                    </textarea>
                    <hr/>
                    <app-audio-player [text]="promptEdit.default_prompt" [api]="api"></app-audio-player>
                </td>

                <td>
                    <textarea rows="4" cols="50" [(ngModel)]="promptEdit.custom_prompt">
                    </textarea>
                    <hr/>
                    <app-audio-player [text]="promptEdit.custom_prompt" [api]="api"></app-audio-player>
                </td>

                <td>
                    <div *ngIf="isLoaded">
                        <!-- Checkbox for no start time -->
                        <input id="no-start" type="checkbox" [ngModel]="start?.disabled"
                               (change)="onDatetimeClear($event, true)">
                        <label for="no-start">No start date</label>

                        <!-- Ionic datepicker -->
                        <div *ngIf="!start?.disabled">
                            <ion-datetime-button datetime="start_datetime" style="display:flex; flex-direction: column"></ion-datetime-button>

                            <ion-modal [keepContentsMounted]="!start?.disabled">
                                <ng-template>
                                    <ion-datetime [showDefaultButtons]="false" [(ngModel)]="start.isoTime"
                                                  (ionChange)="onDatetimeChange($event, true)"
                                                  [max]="getMaxTime(true)" id="start_datetime"
                                                  [min]="getMinTime(true)" [preferWheel]="false"
                                                  (ionBlur)="onBlur($event, true)"
                                                  minuteValues="0,15,30,45" name="start_datetime">
                                        <span slot="title">Select a Start Date</span>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngIf="isLoaded">
                        <!-- Checkbox for no end time -->
                        <input id="no-end" type="checkbox" [ngModel]="end?.disabled"
                               (change)="onDatetimeClear($event, false)">
                        <label for="no-end">No end date</label>

                        <!-- Ionic datepicker -->
                        <ng-container *ngIf="!end?.disabled">
                            <ion-datetime-button
                                    datetime="end_datetime" style="display:flex; flex-direction: column">

                            </ion-datetime-button>

                            <ion-modal [keepContentsMounted]="!end?.disabled && end.datetime !== ''">
                                <ng-template>
                                    <ion-datetime [showDefaultButtons]="false" [value]="end.isoTime"
                                                  (ionChange)="onDatetimeChange($event, false)" [min]="getMinTime(false)"
                                                  [max]="getMaxTime(false)" [preferWheel]="false"
                                                  (ionBlur)="onBlur($event, false)"
                                                  minuteValues="0,15,30,45" id="end_datetime" mode="md" name="end_datetime">
                                        <span slot="title">Select an End Date</span>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </ng-container>
                    </div>
                </td>

                <td>{{isPromptActive() ? 'Yes' : 'No'}}</td>
            </tr>
        </tbody>
    </table>

    <div class="center">
        <button type="submit" class="btn btn-default" (click)="submit($event)">Save</button>
    </div>
</div>
