import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

    public confirmMessage:string;

    decline() {
        this.dialogRef.close(false);
        // Font awesome cross button html
        // <i class="fas fa-times"></i>
    }

    accept() {
        this.dialogRef.close(true);
    }
}

