<div class="col1">
    <div class="header">
        <h1>{{ statsTitle }}</h1>
    </div>

    <div class="board" *ngIf="stats$ | async as stats">
        <div class="labels">
            <div class="queueName"></div>
            <div *ngFor="let stat of stats.data">{{ stat.queueName || 'Unknown queue' }}</div>
            <div>TOTAL CALL QUEUES</div>
        </div>

        <div class="queue">
            <div class="queueName">Total Inbound</div>
            <div *ngFor="let stat of stats.data">{{ stat?.totalCalls || 0 }}</div>
            <div>{{ stats.dataSummary?.totalCalls }}</div>
        </div>

        <div class="queue">
            <div class="queueName">Total Callbacks</div>
            <div *ngFor="let stat of stats.data">{{ stat?.callbacksHandled || 0 }}</div>
            <div>{{ stats.dataSummary?.callbacksHandled }}</div>
        </div>

        <div class="queue">
            <div class="queueName">Handled / Abandoned</div>
            <div *ngFor="let stat of stats.data">{{ stat?.contactsHandled || 0 }}
                / {{ stat?.contactsAbandoned || 0 }}</div>
            <div>{{ stats.dataSummary?.contactsHandled }} / {{ stats.dataSummary?.contactsAbandoned }}</div>
        </div>

        <div class="queue">
            <div class="queueName">Calls Waiting</div>
            <div *ngFor="let stat of stats.data">{{ stat?.callsWaiting || 0 }}</div>
            <div [ngClass]="{ alert: stats.dataSummary?.callsWaitingOverThreshold }">{{ stats.dataSummary?.callsWaiting }}</div>
        </div>

        <div class="queue">
            <div class="queueName">ASA</div>
            <div *ngFor="let stat of stats.data">{{ stat?.ASA || 0 }}</div>
            <div>{{ stats.dataSummary?.ASA }}</div>
        </div>

        <div class="queue">
            <div class="queueName">Longest Wait Time</div>
            <div [ngClass]="{ alert: stat?.oldestContactOverThreshold }" *ngFor="let stat of stats.data">
                {{ stat?.oldestContact }}
            </div>
            <div>{{ stats.dataSummary?.oldestContact }}</div>
        </div>
    </div>
    <app-status-summary [available]="available" [notAvailable]="notAvailable"
                        [outOfOffice]="outOfOffice"></app-status-summary>
</div>
<div class="col2">
    <ion-slides pager="false" [options]="slideOpts" class="wallboard-slides">
        <ion-slide>
            <div class="page">
                <app-card-frame
                        *ngFor="let statusGroup of agentsPage1$ | async"
                        [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName"
                        class="frame"
                >
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent"></app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <ion-slide *ngIf="(agentsPage2$ | async)?.length > 0">
            <div class="page">
                <app-card-frame
                        *ngFor="let statusGroup of agentsPage2$ | async"
                        [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName"
                        class="frame"
                >
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent"></app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <ion-slide *ngIf="(agentsPage3$ | async)?.length > 0">
            <div class="page">
                <app-card-frame
                        *ngFor="let statusGroup of agentsPage3$ | async"
                        [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName"
                        class="frame"
                >
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent"></app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <div class="header">
            <h1>{{ agentsTitle }}</h1>
        </div>
        <ion-slide *ngIf="(agentsPage4$ | async)?.length > 0">
            <div class="page">
                <app-card-frame
                        *ngFor="let statusGroup of agentsPage4$ | async"
                        [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName"
                        class="frame"
                >
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent"></app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
    </ion-slides>
</div>
