import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { from, Observable, of, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concatMap, groupBy, map, mergeMap, share, tap, toArray } from 'rxjs/operators';
import { Agent } from '../../../domain/interfaces/agents';
import peelPage from '../../../core/pipes/peel-first-page.operator';
import { LoginService } from '@uoa/auth';

const sortOrder = (statusIdentifier) => {
    switch (statusIdentifier) {
        case 'inbound':
            return 1;
        case 'emails':
            return 2;
        case 'webchat':
            return 3;
        case 'auckland online':
            return 4;
        case 'outbound':
            return 5;
        case 'break':
            return 6;
        case 'team leader':
            return 7;
        default:
            // Z
            return 8;
    }
};

@Component({
    selector: 'app-agent-board',
    templateUrl: './agent-board.component.html',
    styleUrls: ['./agent-board.component.scss'],
})
export class AgentBoardComponent implements OnInit {
    agents$: Observable<Agent[][]>;
    agentsPage1$: Observable<Agent[][]>;
    agentsPage2$: Observable<Agent[][]>;
    agentsPage3$: Observable<Agent[][]>;
    agentsPage4$: Observable<Agent[][]>;

    available: number = 0;
    notAvailable: number = 0;
    outOfOffice: number = 0;

    televisionWidth = 1280;

    tvSlideOpts = {
        speed: 1000,
        autoplay: {
            delay: 20000,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
    };

    desktopSlideOpts = {
        direction: 'vertical',
        slidesPerView: 'auto',
        freeMode: true,
        scrollbar: true,
        mousewheel: true,
    };

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService) {
    }

    statusCompare(a: Agent[], b: Agent[]): number {
        return sortOrder(a[0].CurrentEvent.Status.Item.Identifier) - sortOrder(b[0].CurrentEvent.Status.Item.Identifier);
    }

    dateCompare(a: Agent, b: Agent): number {
        return new Date(a.CurrentEvent.Date).getTime() - new Date(b.CurrentEvent.Date).getTime();
    }

    ngOnInit() {
        this.route.data.subscribe((api) => {
            if (environment.wallboards.staff.agents.mockData) {
                // ascending by status, descending by time
                this.agents$ = of(
                    environment.wallboards.staff.agents.mockData.sort(
                        (a, b) => a.statusCode.localeCompare(b.statusCode) || b.timeSeconds - a.timeSeconds
                    )
                );
            } else {
                let options = {};
                let urlSuffix = 'agents';
                const apikey = this.route.snapshot.params['key'];
                if (apikey) {
                    options = {
                        headers: {
                            'x-api-key': apikey,
                        },
                    };
                    urlSuffix = '-tv';
                }
                // soft reload timer
                this.agents$ = timer(0, 8000 * 600).pipe(
                    // workaround until auth library can handle `x-amzn-errortype: UnauthorizedException` header
                    tap(async (_) => {
                        if (!apikey) {
                            await this.loginService.doLogin(this.router.url);
                        }
                    }),
                    concatMap(() =>
                        this.httpClient.get<any>(api.agents.baseUrl + urlSuffix, options).pipe(
                            // agents
                            tap((s) => {
                                console.log('Updating board using', s.data?.Items);
                                this.available = s.data?.Items.filter(
                                    (item) =>
                                        item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'green' &&
                                        (item.CurrentEvent?.Status?.Item?.Visible === true || item.CurrentEvent?.Status?.Item?.Visible === 'true')
                                ).length;
                                this.notAvailable = s.data?.Items.filter(
                                    (item) =>
                                        item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'orange' &&
                                        (item.CurrentEvent?.Status?.Item?.Visible === true || item.CurrentEvent?.Status?.Item?.Visible === 'true')
                                ).length;
                                this.outOfOffice = s.data?.Items.filter(
                                    (item) =>
                                        item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'red' &&
                                        (item.CurrentEvent?.Status?.Item?.Visible === true || item.CurrentEvent?.Status?.Item?.Visible === 'true')
                                ).length;
                            }),
                            map((s) =>
                                s.data.Items.filter(
                                    (s) =>
                                        s.CurrentEvent?.Status?.Item?.Visible &&
                                        s.CurrentEvent?.Status?.Item?.Visible !== 'false' &&
                                        s.FullAgentName &&
                                        !s.FullAgentName.includes('IAM')
                                )
                            ),
                            // split array into separate emitted objects
                            mergeMap((s: Agent[]) => from(s)),
                            // group objects together by identifier
                            groupBy((s: Agent) => s.CurrentEvent.Status.Item.GroupName),
                            // convert each group to arrays
                            mergeMap((group) => group.pipe(toArray())),
                            // sort within each group: date descending
                            // tap(c => console.info(c)),
                            map((agentsInGroup: Agent[]) => agentsInGroup.sort(this.dateCompare)),
                            // do the opposite of the split above, and emit a single array (of arrays)
                            toArray(),
                            // sort status groups
                            map((groupedAgents: Agent[][]) => groupedAgents.sort(this.statusCompare)),

                            catchError(() => of([]))
                        )
                    )
                );

                this.agents$.subscribe((agentGroups) => console.log(agentGroups));

                // avoid doing http call multiple times
                const sharedAgentGroups = this.agents$.pipe(share());

                this.agentsPage1$ = sharedAgentGroups.pipe((s) => peelPage(s, 1));
                this.agentsPage2$ = sharedAgentGroups.pipe((s) => peelPage(s, 2));
                this.agentsPage3$ = sharedAgentGroups.pipe((s) => peelPage(s, 3));
                this.agentsPage4$ = sharedAgentGroups.pipe((s) => peelPage(s, 4));

                // hard reload timer in case the above timer gets jammed throughout the day
                window.setTimeout(() => {
                    window.location.reload();
                }, 1000 * 60 * 60);
            }
        });
    }
}
