import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { from, Observable, of, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Agent } from '../../../domain/interfaces/agents';
import { catchError, concatMap, groupBy, map, mergeMap, share, tap, toArray } from 'rxjs/operators';
import { LoginService } from '@uoa/auth';
import peelPage from '../../../core/pipes/peel-first-page.operator';
import * as moment from 'moment';

@Component({
    selector: 'app-stats-board',
    templateUrl: './stats-board.component.html',
    styleUrls: ['./stats-board.page1.component.scss'],
})
export class StatsBoardComponent implements OnInit {
    stats$: Observable<{ data: any[]; dataSummary: any }>;
    statsTitle: string;
    agentsTitle: string;

    agents$: Observable<Agent[][]>;
    agentsPage1$: Observable<Agent[][]>;
    agentsPage2$: Observable<Agent[][]>;
    agentsPage3$: Observable<Agent[][]>;
    agentsPage4$: Observable<Agent[][]>;

    available: number = 0;
    notAvailable: number = 0;
    outOfOffice: number = 0;

    slideOpts = {
        speed: 1000,
        autoplay: {
            delay: 20000,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
    };

    moment: any = moment;

    dateCompare(a: Agent, b: Agent): number {
        return new Date(a.CurrentEvent.Date).getTime() - new Date(b.CurrentEvent.Date).getTime();
    }

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService) {
    }

    ngOnInit() {
        this.route.data.subscribe((api) => {
            console.log('Got API: ', api);

            if (environment.wallboards.staff.stats.mockData) {
                console.log('fake api');
                this.stats$ = of(environment.wallboards.staff.stats.mockData).pipe(map(this.mapStats));
                console.log('Using stats mock data: ', environment.wallboards.staff.stats.mockData);
                this.statsTitle = api.stats?.title;
            } else {
                console.log('real api');
                let options = {};
                let urlSuffix = '';
                const apikey = this.route.snapshot.params['key'];
                if (apikey) {
                    options = {
                        headers: {
                            'x-api-key': apikey,
                        },
                    };
                    urlSuffix = '-tv';
                }
                this.statsTitle = api.stats?.title;
                // soft reload timer
                this.stats$ = timer(0, 4000).pipe(
                    // workaround until auth library can handle `x-amzn-errortype: UnauthorizedException` header
                    tap(async (_) => {
                        if (!apikey) {
                            await this.loginService.doLogin(this.router.url);
                        }
                    }),
                    concatMap(() =>
                        this.httpClient.get<any>(api.stats.baseUrl + urlSuffix, options).pipe(
                            tap((s) => {
                                console.log('Got stats data: ', s);
                                console.log('Reloading', s);
                            }),
                            map(this.mapStats),
                            tap((s) => console.log('s', s)),

                            tap(async (_) => {
                                // Get logged in user
                                const user = await this.loginService.getUserInfo();
                                console.log('User: ', user);
                            }),
                            catchError((error) => {
                                if (error.status === 429) {
                                    console.warn('API is rate limited. You may need to adjust the rate limiting threshold.');
                                    return of(error);
                                }
                                return of({ data: null, dataSummary: null });
                            })
                        )
                    )
                );
            }

            if (environment.wallboards.staff.agents.mockData) {
                console.log('Using agents mock data: ', environment.wallboards.staff.agents.mockData);
                // ascending by status, descending by time
                this.agents$ = of(environment.wallboards.staff.agents.mockData).pipe(
                    // agents
                    tap(this.tapAgents),
                    map(this.filterAgents),
                    // split array into separate emitted objects
                    mergeMap((s: Agent[]) => from(s)),
                    // group objects together by identifier
                    groupBy((s: Agent) => s.CurrentEvent.Status.Item.GroupName),
                    // convert each group to arrays
                    mergeMap((group) => group.pipe(toArray())),
                    // sort within each group: date descending
                    //tap(c => console.info(c)),
                    map((agentsInGroup: Agent[]) => agentsInGroup.sort(this.dateCompare)),
                    // do the opposite of the split above, and emit a single array (of arrays)
                    toArray(),

                    catchError(() => of([]))
                );
                this.subscribeAgents();
            } else {
                let options = {};
                let urlSuffix = '';
                const apikey = this.route.snapshot.params['key'];
                if (apikey) {
                    options = {
                        headers: {
                            'x-api-key': apikey,
                        },
                    };
                    urlSuffix = '-tv';
                }
                console.log('Getting agents');
                // soft reload timer
                this.agents$ = timer(0, 8000).pipe(
                    // workaround until auth library can handle `x-amzn-errortype: UnauthorizedException` header
                    tap(async (_) => {
                        if (!apikey) {
                            await this.loginService.doLogin(this.router.url);
                        }
                    }),
                    concatMap(() =>
                        this.httpClient.get<any>(api.agents.baseUrl + urlSuffix, options).pipe(
                            // agents
                            tap(this.tapAgents),
                            map(this.filterAgents),
                            // split array into separate emitted objects
                            mergeMap((s: Agent[]) => from(s)),
                            // group objects together by identifier
                            groupBy((s: Agent) => s.CurrentEvent.Status.Item.GroupName),
                            // convert each group to arrays
                            mergeMap((group) => group.pipe(toArray())),
                            // sort within each group: date descending
                            //tap(c => console.info(c)),
                            map((agentsInGroup: Agent[]) => agentsInGroup.sort(this.dateCompare)),
                            // do the opposite of the split above, and emit a single array (of arrays)
                            toArray(),

                            catchError(() => of([]))
                        )
                    )
                );

                this.subscribeAgents();
            }

            // hard reload timer in case the above timer gets jammed throughout the day
            window.setTimeout(() => {
                window.location.reload();
            }, 1000 * 60 * 60);
        });
    }

    mapStats = (s) => ({
        data: s.data
            .filter((n) => !n.queueName.toLowerCase().includes('unused'))
            .map((q) => ({
                ...q,
                oldestContactOverThreshold: q.oldestContactOverThreshold ? q.oldestContactOverThreshold : q.oldestContact >= 3600,
                oldestContact: q.oldestContact
                    ? moment
                        .utc(q.oldestContact * 1000)
                        .format(q.oldestContactOverThreshold ? q.oldestContactOverThreshold : q.oldestContact >= 3600 ? 'HH:mm:ss' : 'mm:ss')
                    : '-',
                queueName: q.queueName.replace('Queue', ''),
            })),
        dataSummary: {
            ...s.dataSummary,
            callsWaitingOverThreshold: s.dataSummary?.callsWaiting > 5,
            gosOverThreshold: s.dataSummary?.GOS < 80 && s.dataSummary?.GOS >= 0.01,
        },
        totalEmails: s.emailReportData?.rows ? s.emailReportData.rows[0][0] : 0,
    });

    filterAgents = (s) => {
        return s.data.Items.filter((a) => true);
        s.data.Items.filter(
            (s) =>
                // s.CurrentEvent?.Status?.Item?.Visible &&
                // s.CurrentEvent?.Status?.Item?.Visible !== 'false' &&
                s.FullAgentName &&
                !s.FullAgentName.includes('IAM')
        );
    }

    tapAgents = (s) => {
        console.log('Got agent data: ', s);
        console.log('Updating board using', s.data?.Items);
        this.available = s.data?.Items.filter(
            (item) =>
                item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'green' /*&&
        (item.CurrentEvent?.Status?.Item?.Visible !== true || item.CurrentEvent?.Status?.Item?.Visible !== 'true')*/
        ).length;
        this.notAvailable = s.data?.Items.filter(
            (item) =>
                item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'orange' /*&&
        (item.CurrentEvent?.Status?.Item?.Visible !== true || item.CurrentEvent?.Status?.Item?.Visible !== 'true')*/
        ).length;
        this.outOfOffice = s.data?.Items.filter(
            (item) =>
                item.CurrentEvent?.Status?.Item?.Colour.toLowerCase() === 'red' /*&&
        (item.CurrentEvent?.Status?.Item?.Visible !== true || item.CurrentEvent?.Status?.Item?.Visible !== 'true')*/
        ).length;
    };

    subscribeAgents = () => {
        this.agents$.subscribe((agentGroups) => console.log('Agent groups: ', agentGroups));

        // avoid doing http call multiple times
        const sharedAgentGroups = this.agents$.pipe(share());

        this.agentsPage1$ = sharedAgentGroups.pipe((s) => peelPage(s, 1));
        this.agentsPage2$ = sharedAgentGroups.pipe((s) => peelPage(s, 2));
        this.agentsPage3$ = sharedAgentGroups.pipe((s) => peelPage(s, 3));
        this.agentsPage4$ = sharedAgentGroups.pipe((s) => peelPage(s, 4));
    };
}
