import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-status-summary',
  templateUrl: './status-summary.component.html',
  styleUrls: ['./status-summary.component.scss'],
})
export class StatusSummaryComponent implements OnInit {

  @Input() available: number = 0;
  @Input() notAvailable: number = 0;
  @Input() outOfOffice: number = 0;
  
  currentDate$: Observable<string>;

  constructor() {
      console.log('app status summary constructor');
  }

  ngOnInit() {
      console.log('app status summary init');
    this.currentDate$ = new Observable<string>(observer => {
      setInterval(() => observer.next(moment(new Date()).format("LLLL")), 1000);
    });
  }

}
