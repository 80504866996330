import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { LoginService } from '@uoa/auth';
import { Prompt } from '../../../domain/interfaces/prompts';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
    selector: 'app-prompt-list',
    templateUrl: './prompt-list.component.html',
    styleUrls: ['./prompt-list.component.scss'],
})
export class PromptListComponent implements OnInit {

    public contactFlowList: string[];
    public contactFlowFilter = 'All';

    public keywordList: string[];
    public keywordFilter = 'All';

    public promptList: Prompt[] = [];
    public api: Data;

    // tslint:disable-next-line:max-line-length
    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService, private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.route.data.subscribe((api) => {
            console.log('Got API: ', api);
            this.api = api;

            if (environment.wallboards.prompt.useMockData) {
                console.log('mock data');

                // Set the contact flow list and filter out duplicates
                this.contactFlowList = api.prompts.mockData.data.Items
                    .map(prompt => prompt.flow_name)
                    .filter((value, index, self) => self.indexOf(value) === index);

                // Add a blank item to start the list
                this.contactFlowList.unshift('All');

                // Set the keyword list and filter out duplicates
                this.keywordList = api.prompts.mockData.data.Items
                    .map(prompt => prompt.keyword)
                    .filter((value, index, self) => self.indexOf(value) === index);

                // Add a blank item to start the list
                this.keywordList.unshift('All');

                // Set the prompt list
                this.promptList = environment.wallboards.prompt.prompts.mockData.data.Items;
            } else {
                console.log('real data');

                of(null).pipe(
                    tap(async (_) => {
                        const isAuthed = await this.loginService.isAuthenticated();
                        // get user info
                        const user = await this.loginService.getUserInfo();
                        console.log(user);
                        if (!isAuthed) {
                            await this.loginService.doLogin(environment.auth.redirectUri + this.router.url);
                        }
                    }),
                    // Get the prompts
                    mergeMap(() => this.httpClient.get<any>(api.prompts.baseUrl).pipe(
                        tap((data) => {
                            console.log('data', data);
                        }),
                        tap((prompts) => {
                            // Set the contact flow list and filter out duplicates
                            this.contactFlowList = prompts.map(item => item.flow_name);
                            this.keywordList = prompts.map(item => item.keyword);

                            // Add a blank item to start the list
                            this.contactFlowList.unshift('All');

                            // Filter out duplicates
                            this.contactFlowList = this.contactFlowList.filter((item, index) => this.contactFlowList.indexOf(item) === index);

                            // Filter out any prompts flows that start with "Default" or "Sample" or "ZZ"
                            this.contactFlowList = this.contactFlowList.filter((item) =>
                                !item.startsWith('Default') && !item.startsWith('Sample') && !item.startsWith('ZZ'));

                            // Add a blank item to start the list
                            this.keywordList.unshift('All');

                            // Fillter out duplicate keywords
                            this.keywordList = this.keywordList.filter((item, index) => this.keywordList.indexOf(item) === index);

                            // Go through list and pad the start and end times with 0s if they are less than 13 characters long
                            this.promptList.forEach((prompt) => {
                                if (prompt.start_time.toString().length < 13) {
                                    prompt.start_time = Number(prompt.start_time.toString().toString() + '0'.repeat(13 - prompt.start_time.toString().length));
                                } else if (prompt.start_time.toString().length > 13) {
                                    // Remove characters at the end of the string until it is 13 characters long
                                    prompt.start_time = Number(prompt.start_time.toString().substring(0, 13));
                                }

                                if (prompt.end_time.toString().length < 13) {
                                    prompt.end_time = Number(prompt.end_time.toString().toString() + '0'.repeat(13 - prompt.end_time.toString().length));
                                } else if (prompt.end_time.toString().length > 13) {
                                    // Remove characters at the end of the string until it is 13 characters long
                                    prompt.end_time = Number(prompt.end_time.toString().substring(0, 13));
                                }
                            });

                            // Set the prompt list
                            this.promptList = prompts;
                        }),
                        catchError((err) => {
                            console.log('Error getting prompts: ', err);
                            return of([]);
                        })
                    )),
                    catchError((err) => {
                        console.log('Error getting prompt', err);
                        return of(null);
                    })
                ).subscribe({
                    next: (data) => {
                        console.log('prompt list', this.promptList);
                    }
                });
            }
        });
    }

    computedPromptList() {
        let filteredPromptList = this.promptList;

        if (this.contactFlowFilter !== 'All') {
            filteredPromptList = filteredPromptList.filter((prompt) => {
                return prompt.flow_name === this.contactFlowFilter;
            });
        }

        if (this.keywordFilter !== 'All') {
            // Filter the prompt list by keyword
            filteredPromptList = filteredPromptList.filter((prompt) => {
                return prompt.keyword === this.keywordFilter;
            });
        }

        return filteredPromptList;
    }

    isPromptActive(prompt: Prompt) {
        if (prompt.start_time.toString().length < 13) {
            prompt.start_time = Number(prompt.start_time.toString().toString() + '0'.repeat(13 - prompt.start_time.toString().length));
        } else if (prompt.start_time.toString().length > 13) {
            // Remove characters at the end of the string until it is 13 characters long
            prompt.start_time = Number(prompt.start_time.toString().substring(0, 13));
        }

        if (prompt.end_time.toString().length < 13) {
            prompt.end_time = Number(prompt.end_time.toString().toString() + '0'.repeat(13 - prompt.end_time.toString().length));
        } else if (prompt.end_time.toString().length > 13) {
            // Remove characters at the end of the string until it is 13 characters long
            prompt.end_time = Number(prompt.end_time.toString().substring(0, 13));
        }

        if (prompt.start_time === 0) {
            prompt.start_time = '';
        }
        if (prompt.end_time === 0) {
            prompt.end_time = '';
        }

        const start_time: string | number = prompt.start_time;
        const end_time: string | number = prompt.end_time;

        // @ts-ignore
        if(start_time === '' && end_time === '') {
            return true;
        }

        // If end time is disabled and start time is before current time, return true
        // @ts-ignore
        if (start_time === '' && moment().isBefore(end_time)) {
            return true;
        }

        // If start time is disabled and end time is after current time, return true
        // @ts-ignore
        if (end_time === '' && moment().isAfter(start_time)) {
            return true;
        }

        return moment().isBetween(start_time, end_time);
    }

    getFlowList() {
        // Check if the keyword filter is set to all
        if (this.keywordFilter !== 'All') {
            // Filter the flow list by keyword
            const list =  this.contactFlowList.filter((flow) => {
                return this.promptList.find((prompt) => {
                    return prompt.flow_name === flow && prompt.keyword === this.keywordFilter;
                });
            });

            // Add 'All' to start
            list.unshift('All');

            return list;
        }

        return this.contactFlowList;
    }

    getKeywordList() {
        // Check if the contact flow has been set
        if (this.contactFlowFilter !== 'All') {
            // Filter the keyword list by contact flow
            const list = this.keywordList.filter((keyword) => {
                return this.promptList.filter((prompt) => {
                    return prompt.keyword === keyword && prompt.flow_name === this.contactFlowFilter;
                }).length > 0;
            });

            // Add 'All' to start
            list.unshift('All');

            return list;
        }
        return this.keywordList;
    }

    formatPrompt(text: string) {
        // Remove <speak> tags
        return text.replace('<speak>', '').replace('</speak>', '');
    }

    getDate(date: string | number) {
        // If the date is a number, convert it to a string
        if (typeof date === 'number') {
            date = date.toString();
        }

        if (date.length < 13) {
            // Add '0' to the end until the length is 13
            date = date + '0'.repeat(13 - date.length);
        }

        // Convert date to number
        date = Number(date);

        // Return the date in the format of 'hh:mma dd/MM/yyyy'
        return moment(date).format('hh:mma DD/MM/YYYY');
    }

}
