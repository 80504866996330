import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-self-service',
    templateUrl: './self-service.component.html',
    styleUrls: ['./self-service.component.scss'],
})
export class SelfServiceComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
    ) {
        console.log('SelfServiceContainerComponent');
    }

    ngOnInit() {}

}
