<div style="overflow-y: scroll; height: 100vh; margin: 5px">
    <p>
        Directory list of places:
    </p>

    <ul>
        <li>
            <a href="/" title="Home">Home</a>
        </li>
        <li>
            <a href="/about" title="About">About</a>
        </li>
        <li>
            <a href="/contact" title="Contact">Contact</a>
        </li>
        <li>
            <a href="/prompts" title="Self Service">Self Service</a>
        </li>
        <li>
            <a href="/audit-log" title="Audit Log">Audit Log</a>
        </li>
    </ul>
</div>
