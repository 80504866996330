import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NavigationListComponent } from './navigation-list/navigation-list.component';
import { SelfServiceRoutingModule } from './self-service-routing.module';
import { SelfServiceComponent } from './self-service.component';
import { PromptListComponent } from './prompt-list/prompt-list.component';
import { PromptEditComponent } from './prompt-edit/prompt-edit.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { CommonModule } from '@angular/common';
import { FooterComponent, FooterModule, HeaderComponent, HeaderModule } from '../../shared';
import { FormsModule } from '@angular/forms';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { ConfirmationDialogComponent } from './prompt-edit/confirmation-dialog/confirmation-dialog.component';
import { AuthModule } from '@uoa/auth';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        NavigationListComponent,
        SelfServiceComponent,
        PromptListComponent,
        PromptEditComponent,
        AuditLogComponent,
        FooterComponent,
        HeaderComponent,
        AudioPlayerComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        AuthModule,
        CommonModule,
        HttpClientModule,
        IonicModule,
        PipesModule,
        SelfServiceRoutingModule,
        // header and footer modules
        HeaderModule,
        FooterModule,
        FormsModule,
        MatDialogModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelfServiceModule {
}
