import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '@uoa/auth';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService) {
    }

    ngOnInit() {
        of(null).pipe(
            map(async () => {
                    // Console out current logged-in user
                    const user = await this.loginService.getUserInfo();
                    const isAuthenticated = await this.loginService.isAuthenticated();
                    if (!user) {
                        await this.loginService.doLogin(this.router.url);
                    }
                    console.log('Current logged in user: ', user, JSON.stringify(user), isAuthenticated);
                }
            )).subscribe();
    }


}
