import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@uoa/auth';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'prompts',
    },
    {
        path: 'prompts',
        // loadChildren: () => import('./pages/wallboard/wallboards.module').then((m) => m.WallboardsModule),
        loadChildren: () => import('./pages/self-service/self-service.module').then((m) => m.SelfServiceModule),
        // component: SelfServiceContainerComponent,
        // data: { applyPreload: true },
        canActivate: [AuthGuard],
    },
    {
        path: 'error/:errorCode',
        loadChildren: () => import('./error-routing/error-routing.module').then((m) => m.ErrorRoutingModule),
    },

    // Below all TBA
    {
      path: 'wallboards',
      canActivate: [AuthGuard],
      loadChildren: () => import('./pages/wallboard/wallboards.module').then((m) => m.WallboardsModule),
    },
    // {
    //   path: 'self-service',
    //   canActivate: [AuthGuard],
    //   component: SelfServiceContainerComponent,
    //   loadChildren: () => import('./pages/self-service/self-service.module').then((m) => m.SelfServiceModule),
    // },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
