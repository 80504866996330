import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@uoa/auth';
import { environment } from '../../../../environments/environment';
import { Audit } from '../../../domain/interfaces/audit';
import { mergeMap, tap } from 'rxjs/operators';

// Enum for SortType
export enum SortType {
    None = 'None',
    Flow = 'Flow',
    Keyword = 'Keyword',
    Date = 'Date',
    User = 'User',
}

@Component({
    selector: 'app-audit-log',
    templateUrl: './audit-log.component.html',
    styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {

    // Filter by filter type object
    public SortType = SortType;
    sortByType: SortType = null;

    public contactFlowFilter: string;
    public contactFlowList: string[];

    public contactKeywordFilter: string;
    public contactKeywordList: string[];

    public userFilter: string;
    public userList: string[];

    public audits: Audit[];
    public dateFrom: any;
    public dateTo: any;

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService) {
    }

    ngOnInit() {
        // Console out current logged-in user

        this.route.data.subscribe((api) => {
            console.log('Got API: ', api);

            // Check if we're using mock data or not
            if (environment.wallboards.prompt.useMockData) {
                console.log('mock data');

                // Get all audits from the mock data
                this.audits = environment.wallboards.prompt.audit.mockData.data.Items;

                // Sort audits by event timestamp
                this.audits.sort((a, b) => {
                    return a.event_timestamp + b.event_timestamp;
                });

                console.log(this.audits);

            } else {
                console.log('real data');

                // Observable for audit log
                const auditLogObservable = this.httpClient.get<any>(api.audit.baseUrl);

                auditLogObservable.pipe(
                    // Check if user is logged in
                    tap(async (_) => {
                        const isAuthed = await this.loginService.isAuthenticated();
                        if (!isAuthed) {
                            await this.loginService.doLogin(environment.auth.redirectUri + this.router.url);
                        }
                    }),
                    // Get the audit log
                    mergeMap(() => this.httpClient.get<any>(api.audit.baseUrl).pipe(
                        tap((data) => {
                            console.log('data', data);
                        }),
                        tap((audits) => {
                            console.log('Got prompts: ', audits);

                            // Get all p_keys from the audit log and filter out duplicates
                            this.contactFlowList = audits.map((audit) => audit.p_key).filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            }).reverse();

                            // Get all s_keys from the audit log and filter out duplicates
                            this.contactKeywordList = audits.map((audit) => audit.s_key).filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            }).reverse();

                            // Get all user_ids from the audit log and filter out duplicates
                            this.userList = audits.map((audit) => audit.user_id).filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            }).reverse();

                            // Set sortByType to 'None' by default
                            this.sortByType = SortType.None;

                            // Add 'All' to the start of the user list
                            this.userList.unshift('All');

                            // Add 'All' to the start of the contact flow list
                            this.contactFlowList.unshift('All');

                            // Add 'All' to the start of the contact keyword list
                            this.contactKeywordList.unshift('All');

                            // Set to defaults
                            this.contactFlowFilter = 'All';
                            this.contactKeywordFilter = 'All';
                            this.userFilter = 'All';
                            this.dateFrom = null;
                            this.dateTo = null;

                            // Go through all lists and ensure that no values are null or undefined and remove duplicates
                            this.contactFlowList = this.contactFlowList.filter((value, index, self) => {
                                return value != null && self.indexOf(value) === index;
                            });
                            this.contactKeywordList = this.contactKeywordList.filter((value, index, self) => {
                                return value != null && self.indexOf(value) === index;
                            });
                            this.userList = this.userList.filter((value, index, self) => {
                                return value != null && self.indexOf(value) === index;
                            });


                            // Filter out audits from the last 3 months anyway
                            const threeMonthsAgo = new Date();
                            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
                            audits = audits.filter((audit) => {
                                return new Date(audit.event_timestamp) > threeMonthsAgo;
                            });

                            console.log('audits', audits);

                            // Make sure that audits start_time and end_time have 13 digits, append 0 if not
                            audits.forEach((audit) => {
                                if (audit.updated_content.start_time) {
                                    if (audit.updated_content.start_time.toString().length < 13) {
                                        audit.updated_content.start_time = Number(audit.updated_content.start_time.toString().toString() + '0'.repeat(13 - audit.updated_content.start_time.toString().length));
                                    } else if (audit.updated_content.start_time.toString().length > 13) {
                                        // Remove characters at the end of the string until it is 13 characters long
                                        audit.updated_content.start_time = Number(audit.updated_content.start_time.toString().substring(0, 13));
                                    }
                                }

                                if (audit.updated_content.end_time) {
                                    if (audit.updated_content.end_time.toString().length < 13) {
                                        audit.updated_content.end_time = Number(audit.updated_content.end_time.toString().toString() + '0'.repeat(13 - audit.updated_content.end_time.toString().length));
                                    } else if (audit.updated_content.end_time.toString().length > 13) {
                                        // Remove characters at the end of the string until it is 13 characters long
                                        audit.updated_content.end_time = Number(audit.updated_content.end_time.toString().substring(0, 13));
                                    }
                                }

                                if (audit.original_content.start_time) {
                                    if (audit.original_content.start_time.toString().length < 13) {
                                        audit.original_content.start_time = Number(audit.original_content.start_time.toString().toString() + '0'.repeat(13 - audit.original_content.start_time.toString().length));
                                    } else if (audit.original_content.start_time.toString().length > 13) {
                                        // Remove characters at the end of the string until it is 13 characters long
                                        audit.original_content.start_time = Number(audit.original_content.start_time.toString().substring(0, 13));
                                    }
                                }

                                if (audit.original_content.end_time) {
                                    if (audit.original_content.end_time.toString().length < 13) {
                                        audit.original_content.end_time = Number(audit.original_content.end_time.toString().toString() + '0'.repeat(13 - audit.original_content.end_time.toString().length));
                                    } else if (audit.original_content.end_time.toString().length > 13) {
                                        // Remove characters at the end of the string until it is 13 characters long
                                        audit.original_content.end_time = Number(audit.original_content.end_time.toString().substring(0, 13));
                                    }
                                }
                            });

                            this.audits = audits;
                        }),
                    )),
                ).subscribe((data) => {
                    console.log('data', data);
                    // Sort audits by event timestamp
                    this.audits.sort((a, b) => {
                        return a.event_timestamp + b.event_timestamp;
                    });

                    this.audits = data;
                });
            }
        });
    }

    // Sort by type computed list
    sortByTypeList() {
        const list = Object.keys(SortType).map((key) => SortType[key]);
        // Alphabetically sort the SortType list but leaving 'None' at the start
        list.sort((a, b) => {
            if (a === 'None') {
                return -1;
            } else if (b === 'None') {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        });
        return list;
    }

    computedAudits() {
        // Check which filter is selected
        if (this.sortByType === SortType.Flow) {
            return this.filterAuditsByFlow(this.contactFlowFilter);
        } else if (this.sortByType === SortType.Keyword) {
            return this.filterAuditsByKeyword(this.contactKeywordFilter);
        } else if (this.sortByType === SortType.Date) {
            return this.filterAuditsByDate();
        } else if (this.sortByType === SortType.User) {
            return this.filterAuditsByUser(this.userFilter);
        }
        return this.audits;
    }

    filterAuditsByUser(user: string) {
        // Check that it's not 'All'
        if (user === 'All') {
            return this.audits;
        }
        return this.audits.filter((audit) => {
            return audit.user_id === user;
        });
    }

    filterAuditsByFlow(flowName: string) {
        // Check that it's not 'All'
        if (flowName === 'All') {
            return this.audits;
        }
        return this.audits.filter((audit) => {
            return audit.p_key === flowName;
        });
    }

    filterAuditsByKeyword(keyword: string) {
        // Check that it's not 'All'
        if (keyword === 'All') {
            return this.audits;
        }
        return this.audits.filter((audit) => {
            return audit.s_key === keyword;
        });
    }

    filterAuditsByDate() {
        return this.audits.filter((audit) => {
            // Convert dateFrom to a date object
            const dateFrom = new Date(this.dateFrom).valueOf();
            const dateTo = new Date(this.dateTo).valueOf();

            // Audit must be between the dateFrom and dateTo
            return audit.event_timestamp >= dateFrom && audit.event_timestamp <= dateTo;
        });
    }

}
