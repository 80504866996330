<div class="modal">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Are you sure?</h4>
            <button type="button" class="close" aria-label="Close" (click)="decline()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            {{ confirmMessage }}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="decline()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="accept()">Publish</button>
        </div>
    </div>
</div>
