import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnChanges {
    // This component will get data from polly and set the audio

    @Input() public text: string;
    @Input() public api: any;

    public pollyAudio: SafeResourceUrl;

    constructor(private toastController: ToastController, private domSanitizer: DomSanitizer, private httpClient: HttpClient) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Wait 500ms before getting audio from polly and cancel if there's another change
        if (changes.text) {
            setTimeout(() => {
                this.text = changes.text.currentValue;
                this.getPollyAudio();
            }, 500);
        }
    }

    ngOnInit() {
        if (this.text) {
            // Get audio from polly
            this.getPollyAudio();
        }
    }

    getPollyAudio() {
        // Check if this.text starts with and ends with <speak> and </speak> and add them if not
        if (!this.text.startsWith('<speak>')) {
            this.text = '<speak>' + this.text;
        }
        if (!this.text.endsWith('</speak>')) {
            this.text = this.text + '</speak>';
        }

        // Check if there's text
        if (!this.text) {
            // Create toast showing error
            this.toastController.create({
                message: 'No text to convert to audio',
                duration: 2000,
                position: 'top',
                color: 'danger',
            }).then(toast => toast.present());
            return;
        }

        const pollyAudioObservable = this.httpClient.post(this.api.polly.baseUrl, {
            text: this.text,
            type: 'ssml',
        }, { responseType: 'text' });

        pollyAudioObservable.pipe(
            debounce(() => timer(500)),
        ).subscribe({
            next: (pollyAudio: string) => {
                this.pollyAudio = this.sanitisizeAudioUrl(pollyAudio);
            },
            error: (err) => {
                // Create toast showing error
                this.toastController.create({
                    message: 'Error getting audio from polly',
                    duration: 2000,
                    position: 'top',
                    color: 'danger',
                }).then(toast => toast.present());
            }
        });
    }

    sanitisizeAudioUrl(pollyAudio: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl('data:audio/mpeg;base64,' + pollyAudio);
    }
}
