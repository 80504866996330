import { Observable } from 'rxjs';
import { Agent } from 'src/app/domain/interfaces/agents';

export default function peelPage<T extends Agent[][]>(source: Observable<T>, page: number): Observable<T> {
  return new Observable<T>((subscriber) => {
    const subscription = source.subscribe({
      next(value) {
        subscriber.next(value);
        console.log('peelPage: ', value);

        const rowsPerPage = 1;
        const cardsPerRow = 6;
        let rowsTaken = 0;
        let rowsCounted = 0;
        const newValue: Agent[][] = [];

        if (value !== undefined && value !== null) {
          for (const item of value) {
            const rowsForThisGroup = Math.ceil(item.length / cardsPerRow);

            // debugger;
            if (rowsTaken + rowsForThisGroup <= rowsPerPage) {
              rowsCounted += rowsForThisGroup;

              // skip previous pages
              if (rowsCounted > rowsPerPage * (page - 1)) {
                newValue.push(item);
                rowsTaken += rowsForThisGroup;
              }
            } else {
              break;
            }
          }
        }

        subscriber.next(newValue as T);
      },
      error(error) {
        subscriber.error(error);
      },
      complete() {
        subscriber.complete();
      },
    });
    // the return value is the teardown function,
    // which will be invoked when the new
    // Observable is unsubscribed from.
    return () => {
      subscription.unsubscribe();
    };
  });
}
