<div style="width: 100%; height: 100%; overflow-y: scroll; padding-bottom: 100px;">
    <app-header></app-header>

    <nav>
        <ul>
            <li>
                <a [routerLink]="['/prompts']" [routerLinkActive]="['active']">Prompts</a>
            </li>
            <li>
                <a [routerLink]="['/prompts/audit-log']" [routerLinkActive]="['active']">Audit Log</a>
            </li>
            <li>
                <a [routerLink]="['/prompts/help']" [routerLinkActive]="['active']">Help</a>
            </li>
        </ul>
    </nav>

    <div style="padding: 0 25px 50px 25px">
        <router-outlet style="width: 100vw"></router-outlet>
    </div>

    <app-footer></app-footer>
</div>
