<app-status-summary [available]="available" [notAvailable]="notAvailable"
                    [outOfOffice]="outOfOffice"></app-status-summary>

<!-- Mobile, Tablet, Desktop -->
<div class="mobile-cards">
    <div class="page">
        <app-card-frame *ngFor="let statusGroup of agents$ | async"
                        [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName" class="frame">
            <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent">
            </app-agent-card>
        </app-card-frame>
    </div>
</div>

<!-- TV -->
<div class="tv-cards">
    <ion-slides pager="false" [options]="tvSlideOpts" class="wallboard-slides">
        <ion-slide>
            <div class="page">
                <app-card-frame *ngFor="let statusGroup of agentsPage1$ | async"
                                [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName" class="frame">
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent">
                    </app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <ion-slide *ngIf="(agentsPage2$ | async)?.length > 0">
            <div class="page">
                <app-card-frame *ngFor="let statusGroup of agentsPage2$ | async"
                                [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName" class="frame">
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent">
                    </app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <ion-slide *ngIf="(agentsPage3$ | async)?.length > 0">
            <div class="page">
                <app-card-frame *ngFor="let statusGroup of agentsPage3$ | async"
                                [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName" class="frame">
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent">
                    </app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
        <ion-slide *ngIf="(agentsPage4$ | async)?.length > 0">
            <div class="page">
                <app-card-frame *ngFor="let statusGroup of agentsPage4$ | async"
                                [title]="statusGroup[0]?.CurrentEvent.Status.Item.GroupName" class="frame">
                    <app-agent-card *ngFor="let agent of statusGroup" [agent]="agent">
                    </app-agent-card>
                </app-card-frame>
            </div>
        </ion-slide>
    </ion-slides>
</div>
