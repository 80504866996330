import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AuthModule, CognitoConfigService, StorageService } from '@uoa/auth';
import { ErrorPagesModule } from '@uoa/error-pages';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStorageService } from './core/services';
import { AppAuthDesktopConfig } from './auth.desktop.config';
import { StatsBoardComponent } from './pages/call-centres/stats-board/stats-board.component';
import { WallboardsModule } from './pages/wallboard/wallboards.module';
import { AgentBoardComponent } from './pages/call-centres/agent-board/agent-board.component';
import { SelfServiceModule } from './pages/self-service/self-service.module';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SelfServiceRoutingModule } from './pages/self-service/self-service-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent,
        StatsBoardComponent,
        AgentBoardComponent,
    ],

    imports: [
        BrowserModule,
        AuthModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__wallboards',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        AppRoutingModule,
        ErrorPagesModule,
        SelfServiceModule,
        // SelfServiceRoutingModule,
        WallboardsModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule
    ],
    providers: [
        DatePipe,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: CognitoConfigService, useClass: AppAuthDesktopConfig },
        { provide: StorageService, useClass: AppStorageService }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
