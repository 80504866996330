import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@uoa/auth';
import { PromptListComponent } from './prompt-list/prompt-list.component';
import { environment } from '../../../environments/environment';
import { PromptEditComponent } from './prompt-edit/prompt-edit.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { HelpComponent } from './home/help.component';
import { SelfServiceComponent } from './self-service.component';

const routes: Routes = [
    {
        path: '',
        component: SelfServiceComponent,
        data: {
            audit: environment.wallboards.prompt.audit,
            prompts: environment.wallboards.prompt.prompts,
            polly: environment.wallboards.prompt.polly,
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: PromptListComponent,
                data: {
                    audit: environment.wallboards.prompt.audit,
                    prompts: environment.wallboards.prompt.prompts,
                    polly: environment.wallboards.prompt.polly,
                },
            },
            {
                path: 'edit/:flowName/id/:keyword',
                canActivate: [AuthGuard],
                component: PromptEditComponent,
                data: {
                    audit: environment.wallboards.prompt.audit,
                    prompts: environment.wallboards.prompt.prompts,
                    polly: environment.wallboards.prompt.polly,
                },
            },
            {
                path: 'audit-log',
                canActivate: [AuthGuard],
                component: AuditLogComponent,
                data: {
                    audit: environment.wallboards.prompt.audit,
                    prompts: environment.wallboards.prompt.prompts,
                    polly: environment.wallboards.prompt.polly,
                },
            },
            {
                path: 'help',
                component: HelpComponent,
                canActivate: [AuthGuard],
            },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SelfServiceRoutingModule { }
